<template>
  <div class="ntp bg-onelaunch_ui_shades_50 dark:bg-radio_group_text_selected_dark">
    <Head>
      <slot name="headerScripts" />
    </Head>
    <!-- Google Tag Manager (noscript) -->
    <noscript>
      <iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-WNFNMPW"
        height="0"
        width="0"
        style="display: none; visibility: hidden"
      ></iframe>
    </noscript>
    <!-- End Google Tag Manager (noscript) -->
    <div class="ntp__content text-center">
      <div id="logo">
        <!-- Placeholder for the image until it is available -->
        <div v-if="!doodleImage" class="doodle-image" />
        <img v-else class="doodle-image" :src="doodleImage" />
      </div>
      <ClassicNtpCommonSearchbox class="absolute" />
      <slot name="mostVisitedSlot" />
      <slot name="newsSlot" />
    </div>
    <slot />
    <slot v-if="browser !== 'SHIFT'" name="footerSlot" />
  </div>
</template>
<script setup>
  import { useDocumentVisibility } from '@vueuse/core'
  import { setProfileProperty } from '~~/assets/js/newtabpage'
  import { isDevelopment } from '~~/assets/js/common'
  import { updateDoodleStore } from '~~/assets/js/doodle'
  import { useDoodleStore } from '~/store/DoodleStore'
  import { sendMixpanelEvent, isCurrentlyAtHomeTab } from '~/utils/host-browser-apis'
  import { useAutoRefresh } from '~/composables/useAutoRefresh'

  const browser = useBrowser()
  const doodleImage = ref('')
  doodleImage.value = null

  onMounted(() => {
    useAutoRefresh()
    const isHomeTab = isCurrentlyAtHomeTab()
    if (!isHomeTab) {
      const faviconColor =
        window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'white' : 'black'
      useHead({
        link: [
          {
            rel: 'icon',
            type: 'image/svg+xml',
            href: `data:image/svg+xml, <svg width="24" height="24" fill="${faviconColor}" xmlns="http://www.w3.org/2000/svg" ><path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" /></svg>`,
          },
        ],
      })
    }
  })
  const doodleStore = useDoodleStore()
  definePageMeta({
    layout: false,
    pageTransition: false,
  })

  useHead({
    title: 'New Tab',
    script: [
      {
        children:
          "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-WNFNMPW');",
      },
    ],
  })
  const slots = useSlots()
  const hasSlot = () => {
    return !!slots.newsSlot
  }
  const logoMarginTop = hasSlot() ? '56px' : '0'
  const logoPaddingTop = hasSlot() ? '0' : '56px'
  const doodleHeight = hasSlot() ? '140px' : '200px'
  const doodleWidth = hasSlot() ? '336px' : '480px'
  const logoMarginTopMedia = hasSlot() ? '39px' : '0'
  const logoPaddingTopMedia = hasSlot() ? '0' : '56px'
  const logoMarginBottomMedia = hasSlot() ? '10px' : '22px'
  const activeTab = useDocumentVisibility()

  let reload = true
  watch(
    activeTab,
    async (current) => {
      if (current === 'visible' && reload) {
        await sendMixpanelEvent('NtpPage_Viewed')
        reload = false
      }
    },
    { immediate: true }
  )

  onMounted(() => {
    updateDoodleStore()
    doodleImage.value = doodleStore.doodleImage
    // Not able to access the window object out of onMounted
    if (!isDevelopment()) {
      window.dataLayer = window.dataLayer || []
      function gtag() {
        dataLayer.push(arguments)
      }
      gtag('js', new Date())
      gtag('config', 'UA-62117061-22')
    }
    setProfileProperty('ntp_url', location.hostname + location.pathname)
  })
</script>

<style lang="scss" scoped>
  .doodle-image {
    max-width: 100%;
    border-radius: 12px;
    width: 480px;
    height: 200px;
    justify-content: center;
    display: inline-flex;
  }

  .ntp {
    min-height: 100vh;
    position: relative;
    overflow-y: hidden;
  }

  #logo {
    justify-content: center;
    max-width: 1752px;
    display: flex;
    margin: v-bind(logoMarginTop) auto 22px;
    padding: v-bind(logoPaddingTop) 84px;
    position: relative;
  }

  @media (max-width: 1400px) {
    .doodle-image {
      height: v-bind(doodleHeight);
      width: v-bind(doodleWidth);
    }

    #logo {
      margin-top: v-bind(logoMarginTopMedia);
      padding-top: v-bind(logoPaddingTopMedia);
      margin-bottom: v-bind(logoMarginBottomMedia);
    }
  }
</style>
<style lang="scss">
  @import 'bootstrap/scss/bootstrap.scss';
  @import '~/assets/stylesheets/common.scss';

  body {
    font-family:
      Inter,
      Roboto,
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Helvetica,
      Arial,
      sans-serif,
      serif,
      monospace;
  }

  :root {
    --background: #fff;
    --border-color: #dfe1e5;
    --mv-delete-color: #80868b;
    --mv-icon-bgcolor: #f1f3f4;
    --mv-delete-bgcolor: #d3d3d5;

    // [NOTE] The variable below is used on the MostVisitedTiles component and is set here
    // so it only applies the hover color when the pages have the layout "default".
    --mv-hover-bgcolor: #2021241a;
  }

  html body {
    background-color: var(--background);
  }

  .dark .most-visited {
    --mv-hover-bgcolor: #ffffff1a;
  }
</style>
